import {
	getSVGs,
	Loading
} from "./utilities/util";
import Tab from './libraries/Tab';
import CommonController from './libraries/CommonController';
import Swiper from "swiper";

// active scrollBar window
const scrollBar = () => {
	const isChange = window.innerWidth > 1024;
	if (isChange == true) {
		$(window).scroll(function() {
			const heigh = window.scrollY;
			if (heigh > 137) {
				$("header").addClass('sroll');
			} else {
				$("header").removeClass("sroll");
			}
		});
	} else {
		console.log("Saii");
	}

}

const navMobile = () => {
	const btn = $(".navMobile");
	const nav = $(".navBar");
	if (btn) {
		$(btn).click(function(e) {
			e.preventDefault();
			$("#overlay").toggleClass("d-b");
			$("body").toggleClass("ov-h");
			$(btn).toggleClass('active');
			$(nav).toggleClass("active");
		});
	}
	const overlay = $("#overlay");
	$(overlay).click(function(e) {
		e.preventDefault();
		$("#overlay").removeClass("d-b");
		$("body").removeClass("ov-h");
		$(btn).removeClass('active');
		$(nav).removeClass("active");
	});
	const contentsMobile = document.querySelectorAll('[data_content--mobile]');
	const contentsPc = document.querySelectorAll('[data_content--pc]');
	const isChange = window.innerWidth < 1025;
	if (isChange == true) {
		contentsMobile.forEach((item) => {
			const valueContent = item.getAttribute('data_content--mobile');
			item.innerHTML = valueContent;
		})
	} else {
		contentsPc.forEach((item) => {
			const valueContent = item.getAttribute('data_content--pc');
			item.innerHTML = valueContent;
		})
	}
}

const changeHeightFooter = () => {
		const isChange = window.innerWidth < 769;
		const height = $(".footer__inner").height();
		if (isChange == true) {
			$(".footer__wrapper").css('height', height + 110);
			$(window).resize(function() {
				$(".footer__wrapper").css('height', height + 110);
			});
		}
}

// swiper home page video
const swiperForBanner = () => {
	var swiper = new Swiper(".hot-news--banner .swiper-container", {
		loop: true,
		autoplay: {
			delay: 2500,
			disableOnInteraction: false,
		},
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},
		simulateTouch: false,
	});
};

//Swiper Hot Event
const swiperHotEvent = () => {
	var swiper = new Swiper(".home__stuff .swiper-container", {
		slidesPerView: 3,
		slidesPerGroup: 1,
		breakpoints: {
			300: {
				slidesPerView: 1,
			},
			600: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
			},
		},
		navigation: {
			nextEl: ".home__stuff .swiper-button-next",
			prevEl: ".home__stuff .swiper-button-prev",
		},
	});
};

//Swiper New Member
const swiperNewMember = () => {
	var swiper = new Swiper(".home__new-member .swiper-container", {
		slidesPerView: 3,
		slidesPerGroup: 1,
		breakpoints: {
			300: {
				slidesPerView: 1,
			},
			600: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
				centeredSlides: true,
				centeredSlidesBounds: true,
			},
		},
		on: {
			init: function() {
				setTimeout(() => {
					const temp = $(".home__new-member .swiper-slide-active img");
					const width = temp.width();
					$(".home__new-member--info__wrapper").css(
						"width",
						`${width + 35}`
					);
				}, 1000);
			},
		},
		navigation: {
			nextEl: ".fake-swiper-button .swiper-button-next",
			prevEl: ".fake-swiper-button .swiper-button-prev",
		},
	});
	swiper.on("slideChange", function() {
		const data = $(".home__new-member .swiper-slide-active");
		const width = data.width();
		$(".home__new-member--info__wrapper").width(width);
		const temp = data.find(".model--desc");
		$(".home__new-member--info .home__new-member--info__wrapper").html(
			temp.html()
		);
	});
};

const swiperNewMb = () => {
	var swiper = new Swiper(".new-mb .swiper-container", {
		slidesPerView: 4,
		slidesPerGroup: 1,
		breakpoints: {
			300: {
				slidesPerView: 1,
			},
			600: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
				centeredSlides: true,
				centeredSlidesBounds: true,
			},
		},
		on: {
			init: function() {
				setTimeout(() => {
					const temp = $(".new-mb .swiper-slide-active img");
					const width = temp.width();
					$(".new-mb--info__wrapper").css(
						"width",
						`${width + 35}`
					);
				}, 1000);
			},
		},
		navigation: {
			nextEl: ".fake-swiper-button .swiper-button-next",
			prevEl: ".fake-swiper-button .swiper-button-prev",
		},
	});
	swiper.on("slideChange", function() {
		const data = $(".new-mb.swiper-slide-active");
		const width = data.width();
		$(".new-mb--info__wrapper").width(width);
		const temp = data.find(".model--desc");
		$(".new-mb-page--info .new-mb--info__wrapper").html(
			temp.html()
		);
	});
};

//swiper happy birthday
const swiperHappyBirthday = () => {
	var swiper = new Swiper(".home__happy-birthday .swiper-container", {
		slidesPerView: 3,
		slidesPerGroup: 1,
		// loop : true ,
		breakpoints: {
			300: {
				slidesPerView: 1,
			},
			600: {
				slidesPerView: 2,
				spaceBetween: 25,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 45,
			},
		},
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
			reverseDirection: true,
		},
		simulateTouch: false,
		navigation: {
			nextEl: ".home__happy-birthday .swiper-button-next",
			prevEl: ".home__happy-birthday .swiper-button-prev",
		},
	});
	// swiper.on("slideChange", function() {
	// 	getSVGs(); 
	// });
};

//swiper member homepage
const swiperMemberHome = () => {
	var swiper = new Swiper(".home__member--swiper .swiper-container", {
		slidesPerView: 5,
		slidesPerGroup: 1,
		loop: true,
		breakpoints: {
			300: {
				slidesPerView: 3,
			},
			1024: {
				slidesPerView: 5,
			},
		},
		autoplay: {
			delay: 2000,
		},
		simulateTouch: false,
		navigation: {
			nextEl: ".home__member--swiper .swiper-button-next",
			prevEl: ".home__member--swiper .swiper-button-prev",
		},
	});
};

const swiperNews = () => {
	const swiper = new Swiper(".block__HREC.right .swiper-container", {
		slidesPerView: 1,
		slidesPerColumn: 4,
		slidesPerColumnFill: 'row',
		spaceBetween: 10,
		speed: 800,
		navigation: {
			nextEl: ".block__HREC.right .swiper-button-next",
			prevEl: ".block__HREC.right .swiper-button-prev",
		},
	});
	const swipery = new Swiper(".block__HREC.left .swiper-container", {
		slidesPerView: 1,
		slidesPerColumn: 4,
		spaceBetween: 10,
		slidesPerColumnFill: 'row',
		speed: 800,
		navigation: {
			nextEl: ".block__HREC.left .swiper-button-next",
			prevEl: ".block__HREC.left .swiper-button-prev",
		},
	});
};

// MAIN BANNER WEBSITE
const initMainBanner = () => {
	const namePage = document.querySelector("#js-page-verify");
	const mainBanner = document.querySelector(".MainSlider__Banners");
	if (
		(namePage.className == "index-page") |
		(namePage.className == "introduce-page")
	) {
		mainBanner.classList.add("isIndex");
	}
	return new Swiper(".MainSlider__Banners .swiper-container", {
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},
		speed: 1000,
		autoplay: {
			delay: 5000,
			disableOnInteraction: false,
		},
		pagination: {
			el: ".MainSlider__Banners .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});
};

const swipeIntroducePurpose = () => {
	var swiper = new Swiper(".introduce__purpose__wrapper .swiper-container", {
		slidesPerView: 3,
		slidesPerGroup: 1,
		autoplay: {
			delay: 2500,
			disableOnInteraction: false,
		},
		breakpoints: {
			300: {
				slidesPerView: 1,
				spaceBetween: 45,
				loop: true,
				autoplay: {
					delay: 2500,
					disableOnInteraction: false,
				},
			},
			600: {
				slidesPerView: 2,
				spaceBetween: 45,
				loop: true,
			   
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 45,
				autoplay: false
			},
		},
	});
};

const sniperEvent = () => {
	const swiper = new Swiper('.eventBottom__HREC .swiper-container', {
		slidesPerView: 2,
		slidesPerColumn: 4,
		speed: 800,
		breakpoints: {
			300: {
				slidesPerView: 1,
			},
			1024: {
				slidesPerView: 2,
			},
		},
		navigation: {
			nextEl: '.eventBottom__HREC .swiper-button-next',
			prevEl: '.eventBottom__HREC .swiper-button-prev',
		},
	});
}

const swiperNewAdc = () => {
	const swiperL = new Swiper(".nSlider__col.left .swiper-container", {
		loop: true,
		autoplay: {
			delay: 2500,
			disableOnInteraction: false,
		},
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},
		simulateTouch: false,
	});
};

const swiperNewAdcR = () => {
	const swiperR = new Swiper(".nSlider__col.right .swiper-container", {
		loop: true,
		autoplay: {
			delay: 2500,
			disableOnInteraction: false,
		},
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},
		simulateTouch: false,
	});
	const swiperT = new Swiper(".eventTop__slider .swiper-container", {
		loop: true,
		autoplay: {
			delay: 2500,
			disableOnInteraction: false,
		},
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},
		simulateTouch: false,
	});
	const swiperB = new Swiper(".eventBot__slider .swiper-container", {
		loop: true,
		autoplay: {
			delay: 2500,
			disableOnInteraction: false,
		},
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},
		simulateTouch: false,
	});
	const swiperD = new Swiper(".detail__Adc .swiper-container", {
		loop: true,
		autoplay: {
			delay: 2500,
			disableOnInteraction: false,
		},
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},
		simulateTouch: false,
	});
};

const showInputSearch = () => {
	const btn = document.querySelector(".navSearch__btn");
	const navSearch__input = document.querySelector(".navSearch__input");
	if (btn) {
		btn.addEventListener("click", (e) => {
			btn.classList.toggle("active");
			navSearch__input.classList.toggle("show");
		});
	}
};

export const swiperExecutiveCommittee = () => {
	var swiper = new Swiper(".tab-bch .swiper-container", {
		spaceBetween: 20,
		breakpoints: {
			300: {
				slidesPerView: 1,
			},
			500: {
				slidesPerView: 2,
				slidesPerGroup: 1,
			},
			700: {
				slidesPerView: 3,
				slidesPerGroup: 1,
			},
			1025: {
				slidesPerView: 2,
				slidesPerColumn: 2,
			},
		},
		on: {
			init: function() {
				document
					.querySelector(".tab-bch .swiper-container .swiper-slide")
					.click();
			},
		},
		navigation: {
			nextEl: ".tab-bch .swiper-button-next",
			prevEl: ".tab-bch .swiper-button-prev",
		},
	});
	var swiper = new Swiper(".tab-bkt .swiper-container", {
		spaceBetween: 20,
		breakpoints: {
			300: {
				slidesPerView: 1,
			},
			500: {
				slidesPerView: 2,
				slidesPerGroup: 1,
			},
			700: {
				slidesPerView: 3,
				slidesPerGroup: 1,
			},
			1025: {
				slidesPerView: 2,
				slidesPerColumn: 2,
				slidesPerColumnFill: "row",
			},
		},
		on: {
			init: function() {
				document
					.querySelector(".tab-bkt .swiper-container .swiper-slide")
					.click();
			},
		},
		navigation: {
			nextEl: ".tab-bkt .swiper-button-next",
			prevEl: ".tab-bkt .swiper-button-prev",
		},
	});
};

//render info in intro page
const renderInfo = () => {
	const tabbch = document
		.querySelectorAll(
			".tab-bch .swiper-slide"
		)
	tabbch.forEach((item) => {
		item.addEventListener("click", (e) => {
			e.preventDefault();
			const content = item.querySelector(".item__wrapper");
			tabbch.forEach(item => {
				item.classList.remove('active')
			})
			item.classList.add("active")
			$(".tab-bch .executive-committee-active").html(content.innerHTML);
			// const tempheight = $(".executive-committee-active").height();
			// $('html, body').animate({
			// 	scrollTop: $(".executive-committee-active").offset().top - 200
			// }, 2000);
		});
	});
	const tabbkt = document
		.querySelectorAll(
			".tab-bkt .swiper-slide"
		)
	tabbkt.forEach((item) => {
		item.addEventListener("click", (e) => {
			e.preventDefault();
			const temp = item.querySelector("a");
			tabbkt.forEach(item => {
				item.classList.remove("active")
			})
			item.classList.add("active")
			$(".tab-bkt .executive-committee-active").html(temp.innerHTML);
		});

	});
};

const copyDataByAttr = () => {
	const items__paste = document.querySelectorAll('[data-paste]');
	const items__copy = document.querySelectorAll('[data-copy]');

	items__paste.forEach((itemPaste) => {
		items__copy.forEach((itemCopy) => {
			var data = itemCopy.getAttribute('data-copy');
			if (data != null && data == itemPaste.getAttribute('data-paste')) {
				itemPaste.textContent = itemCopy.textContent;
			}
		});
	});
};
// ACTIVE LANGGUAGE
const activeLanguage = () => {
	const htmlLang = document.querySelector('html').getAttribute('lang');
	const items__language = document.querySelectorAll(
		'.header__languages .languages__item'
	);
	items__language.forEach((item) => {
		if (item.getAttribute('data-language') == htmlLang) {
			item.classList.add('active');
		}
	});
};

//fake swiper partner
const fakeSwiperPartner = () => {
	if (document.querySelector(".index-page")) {
		const  imgfromBE =$("#list-img").val()
		const listarray = imgfromBE.split(",")
		var images = Array.from(
			document.querySelectorAll(".partner--logo .img.simulateclass")
		);
		let currentHover = -1;
		let currentChange = 0;
		let indexes = [2, 3, 1, 0];
		let sourceIndexes = listarray.slice(images.length);
		images.map((image, i) => {
			image.setAttribute('id', i);
			image.setAttribute('src', listarray[i]);

			image.addEventListener('mouseenter', event => {
				currentHover = +event.target.getAttribute('id');
				indexes = indexes.filter(index => index !== currentHover);
			});
			image.addEventListener('mouseleave', event => {
				indexes = [2, 3, 1, 0];
				for (let i = 0; i <= currentChange; i++) {
					const item = indexes.pop();
					indexes = [
						item,
						...indexes
					]
				}
				currentHover = -1;
			});
		});
		const onchange = () => {
			currentChange = indexes.pop();
			indexes = [currentChange, ...indexes];
			const currentImage = images.find(image => +image.getAttribute('id') === currentChange);
			const randNum = Math.floor(Math.random() * sourceIndexes.length);
			const sourceUrl = sourceIndexes[randNum];
			sourceIndexes[randNum] = currentImage.getAttribute('src');
			currentImage.setAttribute('src', sourceUrl);
		}
		if(listarray.length > 4 ) {
			setInterval(() => {
				onchange(indexes);
			}, 1000);
		}
	}
};

//get breadcrumb title
const getBreadcrumbTitle = () => {
	let title = $('#breadcrumb-wrapper ul li').eq(1).text();
	$('#breadcrumb-wrapper ul li').last().addClass('active');
	$('.pagesBanner__title h1').text(title);
};


const swiperImage = () => {
	if(document.querySelector(".libary")) {
		$(document).on("click", ".libary--image .block--item--img", function(element) {
			element.preventDefault();
			const slide = element.currentTarget.querySelector(".d-n").innerHTML
			document.querySelector("#popupImage .popup__wrapper").innerHTML =`${slide}`
			$.fancybox.open({
				src: "#popupImage",
				type: "inline",
				opts : {
					afterShow : function() {
						const swiper = new Swiper('#popupImage .swiper-container', {
							speed: 800,
							centeredSlides: true,
							loop: true,
							breakpoints: {
								300: {
									slidesPerView: 1.5,
								},
								1024: {
									slidesPerView: 2.2,
								},
							},
							navigation: {
								nextEl: '#popupImage .swiper-button-next',
								prevEl: '#popupImage .swiper-button-prev',
							},
						});
					}
				}

			})
		})

		// document.querySelectorAll(".libary--image .block--item--img").forEach(item => {
		// 	item.onclick = (e) => {
		// 		e.preventDefault();
		// 		const slide = item.querySelector(".d-n").innerHTML
		// 		document.querySelector("#popupImage .popup__wrapper").innerHTML =`${slide}`
		// 		$.fancybox.open({
		// 			src: "#popupImage",
		// 			type: "inline",
		// 			opts : {
		// 				afterShow : function() {
		// 					const swiper = new Swiper('#popupImage .swiper-container', {
		// 						speed: 800,
		// 						centeredSlides: true,
		// 						loop: true,
		// 						breakpoints: {
		// 							300: {
		// 								slidesPerView: 1.5,
		// 							},
		// 							1024: {
		// 								slidesPerView: 2.2,
		// 							},
		// 						},
		// 						navigation: {
		// 							nextEl: '#popupImage .swiper-button-next',
		// 							prevEl: '#popupImage .swiper-button-prev',
		// 						},
		// 					});
		// 				}
		// 			}

		// 		})
		// 	}
		// })
	}
}
const swiperExecutive = () => {
	var swiper = new Swiper(".executive-committee-page .block-executive-committee .swiper-container", {
		spaceBetween: 20,
		breakpoints: {
			300: {
				slidesPerView: 1,
			},
			500: {
				slidesPerView: 2,
				slidesPerGroup: 1,
			},
			700: {
				slidesPerView: 3,
				slidesPerGroup: 1,
			},
			1025: {
				slidesPerView: 2,
				slidesPerColumn: 2,
			},
		},
		navigation: {
			nextEl: ".executive-committee-page .swiper-button-next",
			prevEl: ".executive-committee-page .swiper-button-prev",
		},
	});
}

const activeExecutiveCommittee = () => {
	document.querySelectorAll(".executive-committee-swiper__wrapper .block__main--model").forEach(item => {
		const parent = item.parentElement.parentElement.parentElement.parentElement.parentElement;
		$(item).on("click" , function(e) {
			parent.querySelectorAll(".block__main--model")
			.forEach(item => {
				item.classList.remove("active")
			})
			this.classList.add("active")
			parent.querySelector(".executive-committee-active").innerHTML = this.outerHTML
		
		})
		parent.querySelector(".block__main--model").click();
		parent.querySelector(".block__main--model").classList.add("active");
	})
}
const activenewmb = () => {

	var swiper = new Swiper(".new-mb .executive-committee__wrapper .swiper-container", {
		slidesPerView: 4,
		spaceBetween: 30,
		breakpoints: {
			300: {
				slidesPerView: 1,
			},
			500: {
				slidesPerView: 2,
			},
			700: {
				slidesPerView: 3,
			},
			1025: {
				slidesPerView: 4,
			},
		},
		on: {
			init: function() {
				// document
				// 	.querySelector(".new-mb .swiper-container .swiper-slide")
				// 	.click();
			},
		},
		navigation: {
			nextEl: ".new-mb .swiper-button-next",
			prevEl: ".new-mb .swiper-button-prev",
		},
	});

		document.querySelectorAll(".new-mb .block__main--model").forEach(item => {
		const parent = item.parentElement.parentElement.parentElement.parentElement.parentElement;
		$(item).on("click" , function(e) {
			parent.querySelectorAll(".block__main--model")
			.forEach(item => {
				item.classList.remove("active")
			})
			this.classList.add("active")
			parent.querySelector(".executive-committee-active").innerHTML = this.outerHTML
		})
		parent.querySelector(".block__main--model").click();
		parent.querySelector(".block__main--model").classList.add("active");
	})
}


const popupImgmem = () => {
	if(document.querySelector(".list-member"))
	$(document).on("click", ".list-member .block__main--model", function(element) {
		element.preventDefault()
		const data = $(this).children()[0].innerHTML
		$("#infomem .list-mem").html(`${data}`);
		$.fancybox.open({
			src: "#infomem",
			type: "inline"
		})
	})
}


const popupHappymem = () => {
	if(document.querySelector(".member__happy-birthday "))
	$(document).on("click", ".member__happy-birthday .block__main--model", function(element) {
		element.preventDefault()
		const data = $(this)[0].innerHTML
		$("#infomem .list-mem").html(`${data}`);
		$.fancybox.open({
			src: "#infomem",
			type: "inline"
		})
	})
}

const checkLengthImageLib = () => {
	if(document.querySelector(".libary")) {
		const blockLength = document.querySelectorAll(".libary .libary--image .block--item--img");
	if(blockLength.length > 9) {
		document.querySelector(".libary .libary--image .image__wrapper").classList.add('full')
	} else {
		document.querySelector(".libary .libary--image .image__wrapper").classList.remove('full')
	}
	}

}

const ajaxImageLib = () => {
	$(document).on("click" , ".libary--image .pagination li" ,function(e) {
		e.preventDefault();
		const _this = $(this)
		const url = $(this).find("a").attr("href");
		$.ajax({
			url: url,
			type: 'get',
			success: function(res) {
				_this.parent().parent().html(res);
				checkLengthImageLib();
			},
			error: function(res) {
				alert(res);
			}
		})
	})
}

const ajaxDocumentLib = () => {
	$(document).on("click" , ".libary-document .pagination li" ,function(e) {
		e.preventDefault();
		const _this = $(this)
		const url = $(this).find("a").attr("href");
		$.ajax({
			url: url,
			type: 'get',
			success: function(res) {
				_this.parent().parent().html(res)
			},
			error: function(res) {
				alert(res);
			}
		})
	})
}

const ajaxlistmem = () => {
	$(document).on("click" , ".list-member .pagination li" ,function(e) {
		e.preventDefault();
		const _this = $(this)
		const url = $(this).find("a").attr("href");
		$.ajax({	
			url: url,
			type: 'get',
			success: function(res) {
				_this.parent().parent().html(res);
			},
		})
	})
}

const ajaxlistproject = () => {
	$(document).on("click" , ".list-project .pagination li" ,function(e) {
		e.preventDefault();
		const _this = $(this)
		const url = $(this).find("a").attr("href");
		$.ajax({	
			url: url,
			type: 'get',
			success: function(res) {
				_this.parent().parent().html(res);
			},
		})
	})
}


const recaptcha = () => {
	var script = document.createElement('script');
	script.onload = function() {
		console.log("Script loaded and ready");
	};
	if(document.querySelector(".g-recaptcha")) {
		const sitekey = document.querySelector(".g-recaptcha").getAttribute("data-sitekey");
		script.src = `https://www.google.com/recaptcha/api.js?render=${sitekey}`;
		script.setAttribute("async", "");
		script.setAttribute("defer", "");
		document.getElementsByTagName('head')[0].appendChild(script);
	}
	var button = document.createElement("button")
	button.classList.add("fake-button-recaptcha")
	button.onclick = (e) => {
		e.preventDefault();
		window.grecaptcha.ready(function () {
			const recaptcha =document.querySelector('.g-recaptcha');
			const sitekey = recaptcha.getAttribute("data-sitekey")
			grecaptcha.execute(`${sitekey}`, { action: 'HREC' }).then(function (token) {
				recaptcha.value = token
			});
		});
	}
	document.querySelector('body').appendChild(button);
}


const ajaxLogin = () => {
	$("#form__login .ishavepass a").on("click" , function(e) {
		e.preventDefault();
		$.fancybox.open({
			src: "#form__signin",
			type: "inline",
			opts: {
				closeExisting: true,
				afterShow : function() {
				}
			}
		})
	})
	
	$("#form__login .btn-login").on("click" , function(e) {
		e.preventDefault();
		const url = $(this).attr("data-url");
		const formData = new FormData();

		const recaptcha = $(".g-recaptcha")

		$(this).parent().find(".input__login input").each(function() {
			const name = $(this).attr("name");
			const value = $(this).val();
			formData.append(name, value);
		})
		const check = $(this).parent().find(".input__check input");
		formData.append(check.attr("name") , check.is(":checked"));
		formData.append(recaptcha.attr("name") , recaptcha.val());
		if($("#form__login form").valid() === true) {
			$.ajax({
				url: url,
				type: "POST",
				data: formData,
				processData: false,
				contentType: false,
				beforeSend: function() {
					$(this).attr('disabled', 'disabled');
				},
				success: function(res) {
					alert(`${res.Message}`);
					window.location.reload();
				},
			})
		}
		
	})
}

const ajaxRegister = () => {
	$("#form__signin .btn-login").on("click", function(e) {
		e.preventDefault();
		const url =$(this).attr("data-url");
		const formData = new FormData();
		$(this).parent().find(".form-group input[type=file]").each(function(el) {
			const file = $(this)[0].files[0]
			const name = $(this).attr("name")
			formData.append(name , file);
		})
		$(this).parent().find(".form-group input[type=text]").each(function(el) {
			const text = $(this).val()
			const name = $(this).attr("name")
			formData.append(name , text);
		})

		const recaptcha = $(".g-recaptcha")

		formData.append(recaptcha.attr("name") , recaptcha.val());
		if($("#form__signin form").valid() === true) {
			$.ajax({
				url: url,
				type: "POST",
				data: formData,
				processData: false,
				contentType: false,
				beforeSend: function() {
					$(this).attr('disabled', 'disabled');
				},
				success: function(res) {
					alert(`${res.Message}`);
					window.location.reload();
				},
			})
		}
	})
}

const ajaxUpdateInfo = () => {
	$(".date-picker").each(function () {
			$(this).flatpickr({
				// enableTime: true,
				dateFormat: "Y-m-d",
				time_24hr: true,
				maxDate: "today",
			  });
	  });
	  if(document.querySelector(".account")) {
		  document.querySelectorAll(".gender .wrapper").forEach(element => {
			element.addEventListener("click" , (event) => {
				const parent = event.target.parentElement.parentElement.parentElement;
				parent.querySelectorAll("input[type=checkbox]").forEach((e) => {
					e.checked = false
				})
				event.target.parentElement.querySelector("input").checked = true
			  })
		  })
	  }
	$(".tab-user form .btn-login").on("click" , function(e) {
		e.preventDefault();
		const url = $(this).attr("data-url");
		const formData = new FormData();
		$(this).parent().find(".form-group .input>input").each(function(el) {
			const name = $(this).attr("name")
			const val = $(this).val();
			formData.append(name , val);
		})
		$(this).parent().find(".form-group .gender input[type=checkbox]:checked").each(function(e) {
			const name = $(this).attr("name");
			formData.append(name , $(this).val())
		})

		if($(".tab-user form").valid() === true) {
			$.ajax({
				url: url,
				type: "POST",
				data: formData,
				processData: false,
				contentType: false,
				beforeSend: function() {
					$(this).attr('disabled', 'disabled');
				},
				success: function(res) {
					alert(`${res.Message}`);
					window.location.reload();
				},
			})
		}
	})

	$(".tab-project form .btn-login").on("click" , function(e) {
		e.preventDefault();
		const url = $(this).attr("data-url");
		const formData = new FormData();

		$(this).parent().find(".form-group .input>input:not([type=file])").each(function(el) {
			const name = $(this).attr("name")
			const val = $(this).val();
			formData.append(name , val);
		})
			$(this).parent().find(".form-group .input>textarea").each(function(el) {
			const name = $(this).attr("name")
			const val = $(this).val();
			formData.append(name , val);
		})

		// $(this).parent().find(".form-group input[type=file]").each(function(el) {
		// 	const fileList = []
		// 	 for (let i = 0; i <  $(this)[0].files.length; i++) {
		// 		fileList.push($(this)[0].files[i])
		// 	 }
		// 	const name = $(this).attr("name")
		// 	formData.append(name , fileList);
		// })
		$(this).parent().find(".form-group input[type=file]").each(function(el) {
			const file = $(this)[0].files[0]
			const name = $(this).attr("name")
			formData.append(name , file);
		})

		if($(".tab-project form").valid() === true) {
			$.ajax({
				url: url,
				type: "POST",
				data: formData,
				processData: false,
				contentType: false,
				beforeSend: function() {
					$(this).attr('disabled', 'disabled');
				},
				success: function(res) {
					alert(`${res.Message}`);
					window.location.reload();
				},
			})
		}
	})
}

const changeFileInput = () => {
	$(".dropdown .text").on("click" , function() {
		$(this).parent().toggleClass("drop")
	})

	$("input[type=file]").on("change" , function(el) {
		// if(this.files.length > 1) {
		// 	const noti = $(this).attr("data-length").replace("$" , this.files.length)
		// 	$(this).parent().find(".fakeinput")[0].innerHTML = noti;

		// } else {
		// 	$(this).parent().find(".fakeinput")[0].innerHTML = $(this).val();
		// }
		$(this).parent().find(".fakeinput")[0].innerHTML = $(this).val();
	})
}
 
const ajaxContactForm = () => {
	$(".contact-form form .btn-submit").on("click", function(e) {
		e.preventDefault();
		const url = $(this).attr("data-url");
		const formData = new FormData();
		$(this).parent().find(".form input").each(function(el) {
			const name = $(this).attr("name");
			const val = $(this).val();
			formData.append(name , val);
		})
		$(this).parent().find(".form textarea").each(function(el) {
			const name = $(this).attr("name")
			const val = $(this).val();
			formData.append(name , val);
		})
		const recaptcha = $(".g-recaptcha")

		formData.append(recaptcha.attr("name") , recaptcha.val());
		if($(".contact-form form").valid() === true) {
			$.ajax({
				url: url,
				type: "POST",
				data: formData,
				processData: false,
				contentType: false,
				beforeSend: function() {
					$(this).attr('disabled', 'disabled');
				},
				success: function(res) {
					alert(`${res.Message}`);
					window.location.reload();
				},
			})
		}
	});
}

const seeProject = () => {
	$(".project-info .item").on("click", function(el) {
		if($(this).find(".d-n").length > 0) {
			const data = $(this).find(".d-n p")
			data.each(function(el)  {
				if(el > 0) {
					if(el < 7) {
						$(".tab-project .form-group input:not([type=file])")[el].value = $(this)[0].innerHTML
					} else if(el == 7) {
						$(".tab-project .form-group")[el].querySelector(".sample-img img").setAttribute("src" , `${$(this)[0].innerHTML}`)
					} else {
						$(".tab-project .form-group textarea")[0].value = $(this)[0].innerHTML
					}
				}
			})
		} else {
			$(".tab-project .form-group")[7].querySelector(".sample-img").style.display = "none"
			document.querySelector(".tab-project form").reset();
		}
	})
}

const checkLogin = () => {
	const check = document.querySelector("#checklogin").value;
	if(check == "false") {
		$(document).on("click" , ".header__login" , function(e) {
			e.preventDefault();
			$.fancybox.open({
				src: "#form__login",
				type: "inline"
			})
		})
	} else {
		return;
	}
}

window.onload = function () {
	const button = document.querySelector(".fake-button-recaptcha");
	button.click();
}

document.addEventListener("DOMContentLoaded", () => {
	activeExecutiveCommittee();
	activenewmb();
	//
	popupHappymem();
	// swiperNewMb();
	swiperExecutive();
	getSVGs();
	Loading();
	scrollBar();
	navMobile();
	//
	popupImgmem();
	recaptcha();
	// checklogin
	checkLogin();
	//
	changeHeightFooter();
	// CommonController
	CommonController();
	//Swiper for banner
	swiperForBanner();
	//swiper for hotvent homepage
	swiperHotEvent();
	// Swiper for new member
	swiperNewMember();
	//Swiper happy birthday
	swiperHappyBirthday();
	//Swiper home member
	swiperMemberHome();
	//Main banner
	initMainBanner();
	//swiper introduce struct
	swipeIntroducePurpose();
	swiperNews();
	sniperEvent();
	swiperNewAdc();
	swiperNewAdcR();
	showInputSearch();
	//render info in swiperExecutiveCommittee
	renderInfo();
	//
	copyDataByAttr();
	// ACTIVE LANGGUAGE
	activeLanguage();
	//fake home parner swiper
	fakeSwiperPartner();
	// showLoginHome
	// showLoginHome();
	//get title breadcum
	getBreadcrumbTitle();
	//
	swiperImage();
	//
	checkLengthImageLib();
	// ajax get Lib Image

	ajaxImageLib();
	// ajax Document Lib
	ajaxDocumentLib();
	//
	ajaxLogin();
	//
	ajaxUpdateInfo();
	//
	changeFileInput();
	//
	ajaxContactForm();
	//
	seeProject();
	//
	ajaxRegister();
	//tab
	const ExecutiveCommittee = new Tab(".executive-committee .tab-container");
	const acount = new Tab(".account .tab-container");
});